<template>
  <v-card>
    <v-card-title>Доставка</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col>
          <text-line title="Имя получателя" :value="order.recipientName" />
        </v-col>
        <v-col>
          <text-line title="Телефон получателя" :value="order.recipientPhone" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <text-line title="Дата доставки" :value="order.deliveryDate" />
        </v-col>
        <v-col>
          <text-line title="Часы доставки" :value="order.deliveryTimeSlot | timeSlot" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <text-line title="Город" :value="order.deliveryAddressCity" />
        </v-col>
        <v-col>
          <text-line title="Улица" :value="order.deliveryAddressStreet" />
        </v-col>
        <v-col>
          <text-line title="Номер дома" :value="order.deliveryAddressStreetNumber" />
        </v-col>
        <v-col>
          <text-line title="Номер квартиры/офис" :value="order.deliveryAddressApartment" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <text-line title="Комментарий курьеру" :value="order.deliveryNotes" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import TextLine from '@/components/ui/TextLine.vue'

export default {
  components: { TextLine },
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>
